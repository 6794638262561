$primaryColor: rgba(6, 161, 243, 0.9);
$secondaryColor: rgba(110, 16, 179, 0.9);

$primary: $primaryColor;

$active-color: $primary;

$border-color: #E3EBF6;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "~easymde/dist/easymde.min.css";

@import "~@zandor300/backoffice-framework/lib/scss/backoffice-framework";

.login-background {
  background-image: linear-gradient(to bottom right, $primaryColor, $secondaryColor), url(./../img/sidebar-background.jpg);
}
.sidebar {
  background-image: linear-gradient(to bottom right, $primaryColor, $secondaryColor), url(./../img/sidebar-background.jpg);
}
